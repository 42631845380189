const IntegrIcon = ({size, color}) => {
    return (
        <div className="icon-gs" style={{width: size, height: size}}>
            <svg width={size} height={size} viewBox="0 0 169 169" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.8417 48.4784C41.8463 48.4784 48.3353 41.9894 48.3353 33.9848C48.3353 25.9802 41.8463 19.4912 33.8417 19.4912C25.8371 19.4912 19.3481 25.9802 19.3481 33.9848C19.3481 41.9894 25.8371 48.4784 33.8417 48.4784Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M135.015 48.4784C143.019 48.4784 149.508 41.9894 149.508 33.9848C149.508 25.9802 143.019 19.4912 135.015 19.4912C127.01 19.4912 120.521 25.9802 120.521 33.9848C120.521 41.9894 127.01 48.4784 135.015 48.4784Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M33.8417 149.651C41.8463 149.651 48.3353 143.162 48.3353 135.157C48.3353 127.153 41.8463 120.664 33.8417 120.664C25.8371 120.664 19.3481 127.153 19.3481 135.157C19.3481 143.162 25.8371 149.651 33.8417 149.651Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M135.015 149.651C143.019 149.651 149.508 143.162 149.508 135.157C149.508 127.153 143.019 120.664 135.015 120.664C127.01 120.664 120.521 127.153 120.521 135.157C120.521 143.162 127.01 149.651 135.015 149.651Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M94.0667 55.6558H74.7888C64.142 55.6558 55.511 64.2868 55.511 74.9336V94.2115C55.511 104.858 64.142 113.489 74.7888 113.489H94.0667C104.714 113.489 113.345 104.858 113.345 94.2115V74.9336C113.345 64.2868 104.714 55.6558 94.0667 55.6558Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M58.7489 110.251L44.6775 124.322" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M58.7476 58.8905L45.1687 45.3115" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M109.758 59.2423L123.829 45.1709" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M124.109 124.252L110.249 110.392" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M77.1816 84.5713H91.6752" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        </div>
    )
}

export default IntegrIcon;