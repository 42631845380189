import './RoleLimit.scss';

const RoleLimit = () => {
  return (
    <div className='RoleLimit'>
      <h1>Данная страница доступна только <span>админу</span></h1>
    </div>
  )
}

export default RoleLimit;