const SettingsIcon = ({size, color}) => {
    return (
        <div className="icon-gs" style={{height: size, width: size}}>
            <svg width={size} height={size} viewBox="0 0 169 170" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M135.015 34.6953H149.508" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.3481 34.6953H106.098" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M120.593 49.048C128.597 49.048 135.086 42.622 135.086 34.6951C135.086 26.7683 128.597 20.3423 120.593 20.3423C112.588 20.3423 106.099 26.7683 106.099 34.6951C106.099 42.622 112.588 49.048 120.593 49.048Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M135.015 135.305H149.508" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.3481 135.305H106.098" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M120.593 149.657C128.597 149.657 135.086 143.231 135.086 135.305C135.086 127.378 128.597 120.952 120.593 120.952C112.588 120.952 106.099 127.378 106.099 135.305C106.099 143.231 112.588 149.657 120.593 149.657Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M62.7581 85H149.508" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.3481 85H33.8417" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M48.2653 99.3527C56.2699 99.3527 62.7591 92.9267 62.7591 84.9998C62.7591 77.073 56.2699 70.647 48.2653 70.647C40.2607 70.647 33.7717 77.073 33.7717 84.9998C33.7717 92.9267 40.2607 99.3527 48.2653 99.3527Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        </div>
    )
}

export default SettingsIcon;